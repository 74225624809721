$primary:       #094151;
$control-grey: #DFE6E8;

$theme-colors: (
    "primary": $primary,
    "control-grey": $control-grey,
    "warning": #ffc107,
    "light-grey": #f6f6f6
);

// $utilities: (
//     "rounded": (
//         property: border-radius,
//         class: rounded,
//         values: (
//             4: 10px,
//         )
//     ),
// );

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;0,600;1,700&display=swap');


html {
    font-size: 12px;
}

body{
    font-family: 'Work Sans', sans-serif;
    background: #eceff0;
    color: $primary;
    padding-bottom: 2em;
}


header {
    background: #f8f8f8 !important;
}


.form-select {
    height: 32px;
}

.dropdown-menu {
    height: 400px;
    overflow-y: scroll;
    z-index: 2000 !important;
}

.country-select button {
    height: 32px;
    border: 1px solid #ced4da;
}

.country-select input {
    position: relative;
    top: -5px;
}

.custom-checkbox {
    width: 1em;
    height: 1em;
    border-radius: 2px;
    border: 1px solid #999;
    position: relative;
    top: -3px;
}

.custom-checkbox-checked {
}

.custom-checkbox-checked::after {
    content: '✓';
    color: #666;
    position: absolute;
    top: -8px;
    font-weight: bold;
    font-size: 1.4em;
    
}

.control-grey {
    background-color: $control-grey  !important;
}

.e-multi-select-wrapper {
    background-color: $control-grey;
}

.date-tag {
    pointer-events: none;
}

.DateRangePicker_1 .DayPicker_transitionContainer {
    border: 1px solid #666;
}

.DateRangePickerInput_1 {
    background-color: $control-grey !important;
    border: none !important;
    height: 30px !important;
    border-radius: 6px;
}

.DateInput_input {
    font-size: 0.8em;
}

.DateInput_input__small {
    font-size: 1rem !important;
}

.DateRangePickerInput__withBorder {
    border-radius: 6px !important;
}

.DateInput_input, .DateInput {
    background: transparent !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid $primary !important;
}

.CalendarDay__selected_span {
    background: $primary !important; 
    border: 1px solid $primary !important; 
}
  
.CalendarDay__selected {
    background: $primary !important;
    border: 1px solid $primary !important;
    color: white;
}

.noUi-target {
    background: $control-grey !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}

.noUi-horizontal {
    height: 100% !important;
}

.noUi-horizontal .noUi-handle {
    min-height:25px;
    height: 100% !important;
    top: 3px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background: $primary !important;
    cursor: pointer !important;
    border: none !important;
}

.noUi-handle:before, .noUi-handle:after {
    height: 10px !important;
    top: 7px !important;
}

.noUi-pips-horizontal {
    top: 0 !important;
}

.noUi-value {
    display: none;
}

.noUi-marker:first-child, .noUi-marker:nth-last-child(2) {
    display:none !important;
}

.noUi-connect {
    background: $primary !important;
    opacity: 0.4;
}